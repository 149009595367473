import React from "react";
import Seo from "../components/seo";
import {Grid, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useIntl} from "gatsby-plugin-intl";
import Layout from "../components/layout";
import theme from "../components/theme";

const useStyles = makeStyles((theme) => ({
    tarifas :{
        borderCollapse:"collapse",
        width:"100%",
        fontFamily: [
            "Helvetica Neue",
            "Arial",
            "sans-serif"
        ].join(",")
    },
    turismoBg :{
        backgroundColor : "#05A0CE",
        color: "#FFF",
    }
}));
//
const Junquillal = () => {
    const intl = useIntl()
    const sectorACG = "Junquillal"
    const classes = useStyles(theme);

    return(
        <Layout sector={sectorACG}>
            <Seo title={intl.formatMessage({id: "jun.titulo"})}
                 description={intl.formatMessage({id: "jun.info1"})}/>
            <Grid container>
                <Grid item md={12}>
                    <Typography variant={"h6"} component={"h2"} >
                        {intl.formatMessage({id: "jun.t"})}
                    </Typography>
                    <Typography variant={"body2"}>
                        <p>
                            {intl.formatMessage({id:"jun.p1"})} <a href={"https://www.junquillallacruz.com/"} target={"_blank"} rel={"noreferrer"}>{intl.formatMessage({id:"jun.aso"})}</a> {intl.formatMessage({id:"jun.p2"})} <a href={"https://www.acguanacaste.ac.cr/turismo/sector-junquillal"} target={"_blank"}>{intl.formatMessage({id: "jun.rvsbj"})}</a>
                        </p>


                        {intl.formatMessage({id: "jun.info1"})}




                    <p>{intl.formatMessage({id:"twha"})}:<a href="https://wa.me/50662315191">+506 6231 5191</a> /&nbsp;<a href="https://wa.me/50661330349">+506 61330349</a> <br />
                    {intl.formatMessage({id:"Email"})}:&nbsp;<a href="mailto:info@junquillallacruz.com">info@junquillallacruz.com</a>&nbsp;/&nbsp;<a href="mailto:reservaciones@junquilallacruz.com">reservaciones@junquilallacruz.com</a> <br />
                    {intl.formatMessage({id:"sweb"})} : <a href={"https://www.junquillallacruz.com/"} target={"_blank"} rel={"noreferrer"}>https://www.junquillallacruz.com/</a>
                    </p>
                    <p>{intl.formatMessage({id: "jun.info3"})}</p>

                    <table className={classes.tarifas} cellSpacing="0" cellPadding="0">
                        <tbody>
                        <tr>
                            <td colSpan="4" className={classes.turismoBg} valign="top"><strong>Tarifas de ingreso por el
                                día</strong></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td valign="top"><strong>{intl.formatMessage({id:"Nacionales"})}</strong></td>
                            <td valign="top"><strong>{intl.formatMessage({id:"Extranjeros"})}</strong></td>
                            <td valign="top"><strong>{intl.formatMessage({id:"Extmd"})} **</strong></td>
                        </tr>
                        <tr>
                            <td valign="top"><strong>{intl.formatMessage({id:"Adultos"})}</strong></td>
                            <td valign="top">¢2260</td>
                            <td valign="top">$13,56</td>
                            <td>$6,78 **</td>
                        </tr>
                        <tr>
                            <td valign="top"><strong>{intl.formatMessage({id:"Menor (2-12 años)"})}</strong></td>
                            <td valign="top">¢1130</td>
                            <td valign="top">$5,65</td>
                        </tr>
                        <tr>
                            <td colSpan="4" className={classes.turismoBg} valign="top"><strong>{intl.formatMessage({id:"campistas   "})}</strong></td>
                        </tr>
                        <tr>
                            <td valign="top"><strong>{intl.formatMessage({id:"Adultos"})}</strong></td>
                            <td>¢4520</td>
                            <td>$18,08</td>
                        </tr>
                        <tr>
                            <td valign="top"><strong>{intl.formatMessage({id:"Menor (2-12 años)"})}</strong></td>
                            <td>¢3390</td>
                            <td>$10,17</td>
                        </tr>
                        <tr>
                            <td colSpan="4" className={classes.turismoBg} valign="top"><strong> {intl.formatMessage({id:"kayak"})}</strong></td>
                        </tr>
                        <tr>
                            <td><strong>{intl.formatMessage({id:"kind"})}</strong></td>
                            <td colSpan="2">¢5000</td>
                        </tr>
                        <tr>
                            <td valign="top"><strong>{intl.formatMessage({id:"kdob"})}</strong></td>
                            <td colSpan="2">¢8000</td>
                        </tr>
                        <tr>
                            <td>** {intl.formatMessage({id:"txgr"})}.&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>

                    <p>{intl.formatMessage({id:"jun.pago"})} </p>
                    </Typography>
                </Grid>
            </Grid>
        </Layout>
    )
}
 export default Junquillal;
